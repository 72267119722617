* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

h1 {
  margin-top: 0px;
  padding-top: 3rem;
}

h2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  background-color: transparent;
  color: rgb(0, 0, 0);
}

a {
  text-decoration: none;
  cursor: pointer !important;
}

.home-link {
  padding: 0.5rem;
}

.home-link-text {
  font-size: 1.5em;
  font-weight: 500;
  color: black;
}

.home-link:hover {
  background-color: rgb(179, 179, 179);
  transition: background-color 0.8s;
  border-radius: 5px;
}

.home {
  text-align: center;
  height: 100vh;
}

.page {
  text-align: center;
}

body {
  min-width: 200px;
  background-color: #dddddd;
}

.App {
  margin: 0rem;
  padding: 0rem;
  background-color: #dddddd;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}


.navigateToHome {
  padding-block: 1.5rem;
}

.exhibition {
  margin-bottom: 1rem;
}

.exhibition-title {
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid black;
  padding: 1rem;
}

.exhibition-title:hover {
  background-color: rgb(179, 179, 179);
  transition: background-color 0.5s;
}

.exhibition-content {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
  height: 100%;
}

.footerText {
  font-size: 1.1em;
  font-weight: 500;
  margin-block: 1rem;
  margin-inline: 0.4rem;
  color: black;
}

@media (max-width: 500px) {
  .footer {
    flex-direction: column;
    height: 5rem;
    gap: 0.5rem;
    font-size: 0.7rem;
  }

  .footerText {
    font-size: 1.1em;
    font-weight: 500;
    margin: 0rem;
    color: black;
  }
}


.footerLink:hover {
  text-decoration: underline;
}

.illustration {
  max-width: stretch;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  object-fit: contain;
}